import React from "react";
import ReactDOM from "react-dom/client";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { BrowserRouter as Router } from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { b2cPolicies, loginRequest, msalConfig } from "./authConfig";
import { MsalAuthService } from "./authService.js";
import App from "./App";
import Cookies from "js-cookie";

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/index.css";

export const CallCenterInstanceName = "citizen-call-center";

// Look for optional "lang" query parameter and use it if provided
const langParam = new URLSearchParams(window.location.search).get("lang");
if (langParam) {
    // Store preferred language in a cookie so that it's persistent across requests to this app
    Cookies.set("lang", langParam);
}

const Language = Cookies.get("lang") || "ja";
const Translations = {
    en: {
        CUSTOMER_SUPPORT_REQUESTS: "Customer Support Requests",
        CUSTOMER: "Customer",
        CUSTOMER_CONTACT: "Contact",
        ONSIGHT_USER: "Onsight User",
        REQUEST_DATETIME: "Request Date/Time",
        STATUS: "Status",

        Canceled: "Canceled",
        NotStarted: "Not Started",
        RequiresCallback: "Requires Callback",
        OnConsulting: "On Consulting",
        Done: "Done",

        CUSTOMER_COMMENT: "Customer Comment",
        TRANSCRIPT: "Transcript",
        RESPONDER: "Responder",
        INITIALIZING_CALL: "Initializing Call",
        PREPARING_TO_CALL: "Preparing to call %s...",
        SORRY_TAKING_LONGER: "Sorry, this is taking longer than expected.",
        CALL_NOW: "Call Now",
        CANCEL: "Cancel",
        CALL_INITIATED_YOU_MAY_CLOSE: "Call initiated. You may now close this window.",
        CALL: "Call",
        LINK: "Link",
        NO_LINK: "None",
        NONE: "None",
        PENDING_LINK: "Pending",
        PLEASE_ASK_YOUR_ADMINISTRATOR: "Please ask your administrator for instructions.",
        ACCESS_RESTRICTED: "Access Restricted",
        MANAGEMENT_PORTAL: "Management Portal",
        NEW_REQUESTS_AVAILABLE: "New requests available. Click to reload.",
        LOADING: "Loading",
        PAGE_WILL_AUTO_CLOSE: "This page will automatically close in %d seconds",
        INVALID_CALLBACK_USER: "Callback failed: ensure that customer's email matches a user in your Onsight domain"
    },
    ja: {
        CUSTOMER_SUPPORT_REQUESTS: "お客様サポート依頼一覧",
        CUSTOMER: "顧客コード",
        CUSTOMER_CONTACT: "顧客名",
        ONSIGHT_USER: "登録アドレス",
        REQUEST_DATETIME: "着信日時",
        STATUS: "状態",

        Canceled: "キャンセル",
        NotStarted: "待機中",
        RequiresCallback: "コールバック依頼",
        OnConsulting: "コール中",
        Done: "対応完了",

        CUSTOMER_COMMENT: "顧客コメント",
        TRANSCRIPT: "文字起こし",
        RESPONDER: "対応者",
        INITIALIZING_CALL: "呼出し中です",
        PREPARING_TO_CALL: "%sとのコールを準備しています",
        SORRY_TAKING_LONGER: "接続しています。少々お待ちください。",
        CALL_NOW: "コールする",
        CANCEL: "キャンセル",
        CALL_INITIATED_YOU_MAY_CLOSE: "コールは始まりました、本ウィンドーを閉じてください",
        CALL: "コール",
        LINK: "リンク",
        NO_LINK: "未対応",
        NONE: "未対応",
        PENDING_LINK: "",
        PLEASE_ASK_YOUR_ADMINISTRATOR: "管理者へ連絡して下さい。",
        ACCESS_RESTRICTED: "アクセス権限エラー",
        MANAGEMENT_PORTAL: "管理ポータル",
        NEW_REQUESTS_AVAILABLE: "新しい受信があります。クリックしてください。",
        LOADING: "ローディング中",
        PAGE_WILL_AUTO_CLOSE: "本ページは%d秒で自動で閉じます。",
        INVALID_CALLBACK_USER: "**Callback failed: ensure that customer's email matches a user in your Onsight domain"
    },
};
export const translate = (key) => {
    const table = Translations[Language];
    if (!table) {
        return key;
    }
    return table[key] || key;
};
/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const msalInstance = new PublicClientApplication(msalConfig);
export const authService = new MsalAuthService(msalInstance, {
    signInAuthority: b2cPolicies.authorities.signIn.authority,
    signInScopes: loginRequest.scopes,
});

let currentPage = {};
export const setCurrentPage = (page) => (currentPage = page);
export const getCurrentPage = () => currentPage;

// Default to using the first account if no account is active on page load
if (
    !msalInstance.getActiveAccount() &&
    msalInstance.getAllAccounts().length > 0
) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

document.title = translate("MANAGEMENT_PORTAL");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Router>
        <MsalProvider instance={msalInstance}>
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={loginRequest}
            >
                <App authService={authService} />
            </MsalAuthenticationTemplate>
        </MsalProvider>
    </Router>
);
